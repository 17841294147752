<h1 class="text-3xl font-medium" i18n>Organisation details</h1>

<div class="mt-12 max-w-sm">
  <p class="text-gray-600 text-sm font-light">Organisation name</p>

  <p class="mb-5 mt-1 text-xl text-black">
    {{ organisation.organisationName }}
  </p>
</div>

<div class="max-w-sm">
  <p class="text-gray-600 text-sm font-light">Customer reference</p>

  <p class="mb-5 mt-1 text-xl text-black">
    {{ organisation.customerReference }}
  </p>
</div>

<div class="relative max-w-sm">
  <p class="text-gray-600 text-sm font-light">Email address</p>

  <p class="mb-5 mt-1 text-xl text-black">
    {{ organisation.email }}
  </p>
</div>

<div class="relative max-w-sm">
  <p class="text-gray-600 text-sm font-light">Phone number</p>

  <p class="mb-5 mt-1 text-xl text-black">
    {{ organisation.phoneNumber }}
  </p>
</div>

<div class="max-w-sm">
  <p class="text-gray-600 text-sm font-light">Site address</p>
  <div class="mb-5 mt-2 flex">
    <img src="/assets/images/location.svg" class="h-4 w-auto" alt="" />
    <p class="ml-3 text-black">{{ organisation.siteAddress }}</p>
  </div>
</div>

<div class="max-w-sm">
  <p class="text-gray-600 text-sm font-light">Date created</p>

  <p class="mt-1 text-xl text-black">{{ organisation.dateCreated }}</p>
</div>